<template>
  <div class="index-container">
    <top-nav />
    <search />
  </div>
</template>

<script>
import Search from '../../shared/Search'
import TopNav from './TopNav.vue'

export default {
  components: { Search, TopNav }
}
</script>
