<template>
  <nav-container>
    <template v-slot:headerName>
      <div class="title">
        Assets
      </div>
    </template>
    <template v-slot:listItems>
      <ul class="menu">
        <li
          v-if="currentUser.permissions.can_read_flashcards"
          :class="assetClasses('items')"
          class="nav-button items"
        >
          <a :href="railsRoutes.cms_path({ type: 'items' })">Items</a>
        </li>
        <li
          v-if="currentUser.permissions.can_read_topics"
          :class="assetClasses('topics')"
          class="nav-button topics"
        >
          <a :href="railsRoutes.cms_path({ type: 'topics' })">Topics</a>
        </li>
        <li
          v-if="currentUser.permissions.can_read_attachments"
          :class="assetClasses('attachments')"
          class="nav-button attachments"
        >
          <a :href="railsRoutes.cms_path({ type: 'attachments' })">Attachments</a>
        </li>
        <li
          v-if="currentUser.permissions.can_read_groupings"
          :class="assetClasses('groupings')"
          class="nav-button groupings"
        >
          <a :href="railsRoutes.cms_path({ type: 'groupings' })">Groupings</a>
        </li>
        <li
          v-if="currentUser.permissions.can_read_learning_modules"
          :class="assetClasses('learning_modules')"
          class="nav-button modules"
        >
          <a :href="railsRoutes.cms_path({ type: 'learning_modules' })">Modules</a>
        </li>
      </ul>
    </template>
  </nav-container>
</template>

<script>
import { mapGetters } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import NavContainer from '../../shared/NavContainer'
export default {
  components: {
    NavContainer
  },
  data () {
    return {
      railsRoutes: railsRoutes
    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  methods: {
    assetClasses (type) {
      var active = false
      if (type === 'attachments') {
        active = this.$router.isRoute('attachment')
      }
      if (type === 'items') {
        active = this.$router.isRoute('item')
      }
      if (type === 'topics') {
        active = this.$router.isRoute('topic')
      }
      if (type === 'groupings') {
        active = this.$router.isRoute('grouping')
      }
      if (type === 'learning_modules') {
        active = this.$router.isRoute('learning_module')
      }

      return {
        'nav-item': true,
        'top-active': active
      }
    }
  }
}
</script>
